import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ALL_AD_SLOTS, AdSlotType } from 'app/constants/googleAds';

const RETRY_LIMIT = 2;

declare global {
  interface Window {
    googletag: any;
  }
}

const useGoogleAds = ({
  type,
  slotKey,
}: {
  type: AdSlotType;
  slotKey: string;
}): { isAdRendered: boolean } => {
  const [slot, setSlot] = useState();
  const [isAdRendered, setIsAdRendered] = useState(true);
  const retryCountRef = useRef(0);

  const googletag = (typeof window !== 'undefined' && window.googletag) || {
    cmd: [],
    pubads: {},
  };

  const adSlots = ALL_AD_SLOTS[type];
  const { adUnitPath, mobileSize, desktopSize, divId } = adSlots[slotKey];

  // Defines the slot before displaying it
  useLayoutEffect(() => {
    try {
      if (googletag.cmd && googletag.pubads) {
        const isMultiSize = mobileSize !== null && desktopSize !== null;
        const size = mobileSize || desktopSize;
        const adSize = isMultiSize ? [mobileSize, desktopSize] : size;

        if (!slot && size !== null) {
          googletag.cmd.push(() => {
            const adSlot = googletag
              .defineSlot(adUnitPath, adSize, divId)
              .addService(googletag.pubads());

            let mapping = null;
            if (isMultiSize) {
              mapping = googletag
                .sizeMapping()
                .addSize([1024, 768], desktopSize)
                .addSize([640, 480], desktopSize)
                .addSize([0, 0], mobileSize)
                .build();
            }

            if (mapping) {
              adSlot.defineSizeMapping(mapping);
            }

            googletag.enableServices();
            setSlot(adSlot);
          });
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Unable to define google ad slot');
    }
  }, [adUnitPath, desktopSize, divId, googletag, mobileSize, slot]);

  // Displays the slot
  // Also refreshes the slot for RETRY_LIMIT if the ad fails to load
  useEffect(() => {
    try {
      if (googletag.cmd && googletag.pubads) {
        googletag.cmd.push(() => {
          googletag.display(divId);

          googletag.pubads().addEventListener('slotRenderEnded', event => {
            if (event.slot.getSlotElementId() === divId) {
              const isRendered = !event.isEmpty;

              if (isRendered) {
                retryCountRef.current = 0;
                setIsAdRendered(true);
              } else if (retryCountRef.current < RETRY_LIMIT) {
                googletag.pubads().refresh();
                retryCountRef.current += 1;
              }
            }
          });
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Unable to display google ad slot');
    }

    return () => {
      if (googletag && googletag.destroySlots && slot) {
        googletag.destroySlots([slot]);
      }
    };
  }, [adUnitPath, divId, googletag, slot]);

  return {
    isAdRendered,
  };
};

export default useGoogleAds;
