import React from 'react';
import Button from 'seedly-component-library/button-new';
import formatCount from 'utils/formatCount';

interface OwnProps {
  name: string;
  discussionsCount: number;
  description: string;
  coverImage: string;
  isSelected: boolean;
  onJoinClick?: () => void;
}

const JoinGroupCard = (props: OwnProps) => {
  const {
    name,
    discussionsCount,
    description,
    coverImage,
    isSelected,
    onJoinClick,
  } = props;

  return (
    <div
      className="card min-h-[218px] max-h-[218px] p-0 border-neutral-400 border rounded-lg"
      data-testid="join-group-card"
    >
      <img
        alt="cover"
        src={coverImage}
        className="object-cover rounded-t h-[45px] w-full"
      />
      <div className="flex flex-col w-full items-center gap-2 p-4">
        <p className="text-sm font-bold text-center">{name}</p>
        <div className="flex flex-col gap-1 w-full items-center">
          <p className="text-center text-xs h-[47px] min-h-fit">
            {description}
          </p>
          <p className="text-center text-xs text-neutral-500">
            {formatCount(discussionsCount)} discussions
          </p>
        </div>
        <Button
          onClick={onJoinClick}
          variant={isSelected ? 'primary' : 'outline'}
          isBlock
        >
          {isSelected ? 'View' : 'Join'}
        </Button>
      </div>
    </div>
  );
};

export default JoinGroupCard;
