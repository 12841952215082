import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useAmp } from 'next/amp';

import useGoogleAds from 'app/utils/hooks/useGoogleAds';
import { ALL_AD_SLOTS, AdSlotType } from 'app/constants/googleAds';
import { generateSize } from './generateSize';

interface AdSlotProps {
  variant?: 'default' | 'bare' | 'card';
  /**
   * default = Ad wrapped in box containing "Advertisement" text
   * bare = Purely just the ad itself
   * card = Ad wrapped in a card containing "Advertisement" text
   */
  type: AdSlotType;
  slotKey: string;
  isCollapsible?: boolean;
}

const AdSlot = ({
  variant = 'default',
  type,
  slotKey,
  isCollapsible = false,
}: AdSlotProps) => {
  const { isAdRendered } = useGoogleAds({ type, slotKey });
  const isAmp = useAmp();
  const adSlotGroup = ALL_AD_SLOTS[type];

  if (isAmp) {
    return null;
  }

  if (!adSlotGroup[slotKey]) {
    return null;
  }

  const { mobileSize, desktopSize, divId } = adSlotGroup[slotKey];

  const { mobile, desktop } = generateSize({
    mobileSize,
    desktopSize,
  });

  const isHidden = useMemo(() => isCollapsible && !isAdRendered, [
    isAdRendered,
    isCollapsible,
  ]);

  const showAdvertisementText = variant !== 'bare';

  return (
    <div
      className={clsx(
        'flex flex-col items-center relative',
        isHidden && 'hidden',
      )}
    >
      {showAdvertisementText && <p className="text-xs mb-2">Advertisement</p>}
      <div
        id={divId}
        className={clsx(
          variant === 'bare' && '[&_iframe]:rounded-lg',
          // only way to enforce min-height
          // we can't inline style the min-heights due to the nature of google ads
          // (mobile and desktop can only use 1 divId)
          mobile.minHeight === 50 && 'min-h-[60px]',
          mobile.minHeight === 200 && 'min-h-[210px]',
          desktop.minHeight === 90 && 'lg:min-h-[100px]',
          desktop.minHeight === 343 && 'lg:min-h-[353px]',
        )}
      />
    </div>
  );
};

export default AdSlot;
