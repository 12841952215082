import { AdSlotGroup } from './types';
import prodAdUnits from './production';

const homeAdSlots: AdSlotGroup = {
  main: {
    ...prodAdUnits.home.main,
    adUnitPath: '/21988789916/test_homepage_main',
    divId: 'div-gpt-ad-1646018595789-0',
  },
  leaderboard: {
    ...prodAdUnits.home.leaderboard,
    adUnitPath: '/21988789916/test_homepage_leaderboard',
    divId: 'div-gpt-ad-1646019682185-0',
  },
};

const communityAdSlots: AdSlotGroup = {
  main: {
    ...prodAdUnits.community.main,
    adUnitPath: '/21988789916/test_community_main',
    divId: 'div-gpt-ad-1646019713062-0',
  },
  skyscraper: {
    ...prodAdUnits.community.skyscraper,
    adUnitPath: '/21988789916/test_community_skyscraper',
    divId: 'div-gpt-ad-1646019740477-0',
  },
};

const blogAdSlots: AdSlotGroup = {
  'top-leaderboard': {
    ...prodAdUnits.blog['top-leaderboard'],
    adUnitPath: '/21988789916/test_article_top_leaderboard',
    divId: 'div-gpt-ad-1646020076401-0',
  },
  'bottom-rectangle': {
    ...prodAdUnits.blog['bottom-rectangle'],
    adUnitPath: '/21988789916/test_article_bottom_rectangle',
    divId: 'div-gpt-ad-1646020099704-0',
  },
  'bottom-leaderboard': {
    ...prodAdUnits.blog['bottom-leaderboard'],
    adUnitPath: '/21988789916/test_article_bottom_leaderboard',
    divId: 'div-gpt-ad-1646020118348-0',
  },
};

const postAdSlots: AdSlotGroup = {
  leaderboard: {
    ...prodAdUnits.post.leaderboard,
    adUnitPath: '/21988789916/test_question_top_leaderboard',
    divId: 'div-gpt-ad-1646020156461-0',
  },
  'bottom-leaderboard': {
    ...prodAdUnits.post['bottom-leaderboard'],
    adUnitPath: '/21988789916/test_post_bottom_leaderboard',
    divId: 'div-gpt-ad-1646020174198-0',
  },
};

const productAdSlots: AdSlotGroup = {
  main: {
    ...prodAdUnits.product.main,
    adUnitPath: '/21988789916/test_product_main',
    divId: 'div-gpt-ad-1646020217981-0',
  },
  bottom: {
    ...prodAdUnits.product.bottom,
    adUnitPath: '/21988789916/test_product_bottom_leaderboard',
    divId: 'div-gpt-ad-1646020199912-0',
  },
  'medium-banner': {
    ...prodAdUnits.product['medium-banner'],
    adUnitPath: '/21988789916/test_product_medium_banner',
    divId: 'div-gpt-ad-1646020237006-0',
  },
};

const opinionAdSlots: AdSlotGroup = {
  leaderboard: {
    ...prodAdUnits.opinion.leaderboard,
    adUnitPath: '/21988789916/test_opinion_top_leaderboard',
    divId: 'div-gpt-ad-1646020280814-0',
  },
  'bottom-leaderboard': {
    ...prodAdUnits.opinion['bottom-leaderboard'],
    adUnitPath: '/21988789916/test_opinion_bottom_leaderboard',
    divId: 'div-gpt-ad-1646020260713-0',
  },
};

export default {
  blog: blogAdSlots,
  home: homeAdSlots,
  post: postAdSlots,
  community: communityAdSlots,
  product: productAdSlots,
  opinion: opinionAdSlots,
};
