import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Carousel } from '@component-lib';
import { useSelectGroupList, useGroupById } from 'entity/group/groupHooks';
import { ChevronRight } from 'react-feather';
import { CommunityPageContext } from 'pageContents/CommunityContent/context/CommunityPageContext';
import { openLoginModal } from 'dispatcher/modalDispatcher';
import { followGroupRequest } from 'entity/group/groupRedux';
import { useIsSignedIn } from 'entity/user/userHooks';
import useIsMobile from 'utils/hooks/useIsMobile';
import useIsNativeApp from 'utils/hooks/useIsNativeApp';
import { useRouter } from 'next/router';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import { shuffle } from 'lodash';
import JoinGroupCard from '../JoinGroupCard';

interface OwnProps {
  title?: React.ReactNode;
  description?: string;
  desktopSlidesToShow?: number;
  desktopSlidesToScroll?: number;
  trackedEvent?: string;
}

const JoinGroupCarousel = (props: OwnProps) => {
  const {
    title = 'Join Community Groups!',
    description = 'Be part of the latest discussions on topics you’re interested in!',
    desktopSlidesToShow = 3,
    desktopSlidesToScroll = 3,
    trackedEvent = trackedEvents.JoinGroupCarousel,
  } = props;
  const [showGroupIds, setShowGroupIds] = useState([]);

  const dispatch = useDispatch();
  const router = useRouter();

  const groupList = useSelectGroupList();
  const groupById = useGroupById();
  const { setPageType } = useContext(CommunityPageContext);
  const isSignedIn = useIsSignedIn();
  const isMobile = useIsMobile();
  const isNativeApp = useIsNativeApp();

  useEffect(() => {
    if (groupList.length > 0 && showGroupIds.length === 0) {
      const unfollowedGroups = shuffle(
        groupList
          .filter(group => {
            return !group.followedByCurrentUser;
          })
          .slice(0, 5),
      );
      const followedGroups = shuffle(
        groupList.filter(group => group.followedByCurrentUser),
      );

      while (unfollowedGroups.length < 5) {
        unfollowedGroups.push(followedGroups.shift());
      }

      setShowGroupIds(unfollowedGroups.map(group => group.id));
    }
  }, [groupList, showGroupIds]);

  if (showGroupIds.length === 0) return null;

  const groupCards = showGroupIds.map(id => {
    const group = groupById[id];
    return (
      <JoinGroupCard
        key={group.id}
        name={group.name}
        description={group.description}
        discussionsCount={group.discussionsCount}
        coverImage={group.coverImage}
        onJoinClick={() => {
          if (!isSignedIn) {
            dispatch(openLoginModal());
            return;
          }

          // if user followed the group
          if (group.followedByCurrentUser) {
            mixpanel.track(trackedEvent, {
              param: `view-${group.slug}`,
            });

            if (isNativeApp) {
              window.location.href = `/community/${group.slug}`;
            } else {
              router.push(`/community/${group.slug}`);
            }
            return;
          }

          mixpanel.track(trackedEvent, {
            param: `join-${group.slug}`,
          });

          dispatch(followGroupRequest(group.id));
        }}
        isSelected={group.followedByCurrentUser}
      />
    );
  });

  groupCards.push(
    <div
      className="flex items-center h-[200px]"
      onClick={() => {
        mixpanel.track(trackedEvent, {
          param: 'see_more_groups',
        });
        if (!isSignedIn) {
          dispatch(openLoginModal());
        } else {
          const route = '/community?discovergroup=true';
          router.push(route, route, { shallow: true });
          setPageType('discover-groups');
        }
      }}
    >
      <div className="flex flex-col w-full gap-4">
        <ChevronRight />
        <p className="text-sm font-bold">See more groups</p>
      </div>
    </div>,
  );

  return (
    <div className="card shadow-none">
      <div className="flex flex-col w-full gap-4">
        <div className="flex flex-col gap-1 w-full">
          <p className="text-base font-bold">{title}</p>
          <p>{description}</p>
        </div>
        <Carousel
          dataSource={groupCards}
          slidesToShow={isMobile ? 2 : desktopSlidesToShow}
          slideToScroll={isMobile ? 2 : desktopSlidesToScroll}
          handleClickForward={() =>
            mixpanel.track(trackedEvent, {
              param: 'next',
            })
          }
        />
      </div>
    </div>
  );
};

export default JoinGroupCarousel;
