import prodAdUnits from './production';
import stagingAdUnits from './staging';

export type { AdSlotItem, AdSlotType } from './types';

const config = {
  development: stagingAdUnits,
  staging: stagingAdUnits,
  beta: prodAdUnits,
  production: prodAdUnits,
  new: prodAdUnits,
};

const ENV = process.env.NEXT_PUBLIC_BUILD_ENV || 'production';

export const ALL_AD_SLOTS = config[ENV];
