import { AdSlotItem } from 'app/constants/googleAds';

export const generateSize = ({
  mobileSize,
  desktopSize,
}: {
  mobileSize: AdSlotItem['mobileSize'];
  desktopSize: AdSlotItem['desktopSize'];
}): {
  mobile: { minWidth: number; minHeight: number };
  desktop: { minWidth: number; minHeight: number };
} => {
  return {
    mobile: {
      minWidth: mobileSize && mobileSize[0],
      minHeight: mobileSize && mobileSize[1],
    },
    desktop: {
      minWidth: desktopSize && desktopSize[0],
      minHeight: desktopSize && desktopSize[1],
    },
  };
};
