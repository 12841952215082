import accounting from 'accounting';

const formatCount = count => {
  return accounting.formatMoney(count, {
    precision: 0,
    symbol: '',
  });
};

export default formatCount;
