import { AdSlotGroup } from './types';

const homeAdSlots: AdSlotGroup = {
  main: {
    adUnitPath: '/21988789916/homepage_main',
    divId: 'div-gpt-ad-1643863402008-0',
    mobileSize: [343, 200],
    desktopSize: [200, 343],
  },
  leaderboard: {
    adUnitPath: '/21988789916/homepage_leaderboard',
    divId: 'div-gpt-ad-1615862861531-0',
    mobileSize: [320, 50],
    desktopSize: [728, 90],
  },
};

const communityAdSlots: AdSlotGroup = {
  main: {
    adUnitPath: '/21988789916/community_main',
    divId: 'div-gpt-ad-1643958013521-0',
    mobileSize: null,
    desktopSize: [200, 343],
  },
  skyscraper: {
    adUnitPath: '/21988789916/community_skyscraper',
    divId: 'div-gpt-ad-1642743300030-0',
    mobileSize: null,
    desktopSize: [160, 600],
  },
};

const blogAdSlots: AdSlotGroup = {
  'top-leaderboard': {
    adUnitPath: '/21988789916/article_top_leaderboard',
    divId: 'div-gpt-ad-1615863277824-0',
    mobileSize: [320, 50],
    desktopSize: [728, 90],
  },
  'bottom-rectangle': {
    adUnitPath: '/21988789916/article_bottom_rectangle',
    divId: 'div-gpt-ad-1615863506290-0',
    mobileSize: [300, 250],
    desktopSize: [300, 250],
  },
  'bottom-leaderboard': {
    adUnitPath: '/21988789916/article_bottom_leaderboard',
    divId: 'div-gpt-ad-1628820180477-0',
    mobileSize: [320, 50],
    desktopSize: [728, 90],
  },
};

const postAdSlots: AdSlotGroup = {
  leaderboard: {
    adUnitPath: '/21988789916/question_top_leaderboard',
    divId: 'div-gpt-ad-1615863831623-0',
    mobileSize: [320, 50],
    desktopSize: [728, 90],
  },
  'bottom-leaderboard': {
    adUnitPath: '/21988789916/post_bottom_leaderboard',
    divId: 'div-gpt-ad-1628754174829-0',
    mobileSize: [320, 50],
    desktopSize: [728, 90],
  },
};

const productAdSlots: AdSlotGroup = {
  main: {
    adUnitPath: '/21988789916/product_main',
    divId: 'div-gpt-ad-1635851154211-0',
    mobileSize: [320, 50],
    desktopSize: [728, 90],
  },
  bottom: {
    adUnitPath: '/21988789916/product_bottom_leaderboard',
    divId: 'div-gpt-ad-1628819893023-0',
    mobileSize: [320, 50],
    desktopSize: [728, 90],
  },
  'medium-banner': {
    adUnitPath: '/21988789916/product_medium_banner',
    divId: 'div-gpt-ad-1644207357634-0',
    mobileSize: null,
    desktopSize: [300, 250],
  },
};

const opinionAdSlots: AdSlotGroup = {
  leaderboard: {
    adUnitPath: '/21988789916/opinion_top_leaderboard',
    divId: 'div-gpt-ad-1628820536036-0',
    mobileSize: [320, 50],
    desktopSize: [728, 90],
  },
  'bottom-leaderboard': {
    adUnitPath: '/21988789916/opinion_bottom_leaderboard',
    divId: 'div-gpt-ad-1628820702574-0',
    mobileSize: [320, 50],
    desktopSize: [728, 90],
  },
};

export default {
  blog: blogAdSlots,
  home: homeAdSlots,
  post: postAdSlots,
  community: communityAdSlots,
  product: productAdSlots,
  opinion: opinionAdSlots,
};
